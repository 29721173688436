<template>
  <div class="security-page">
    <div class="bg">
      <div class="welfare-card">
        <img src="//download-cos.yofish.com/nft-h5-gongjushiyebu/fuli.jpg" alt="" />
      </div>
      <div class="qrcode-card">
        <div class="qrcode" @click="downloadAction"></div>
      </div>
      <div class="bottom-btn" @click="jumpAction">马上免费领</div>
    </div>
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import { userAgentType } from '@/utils';

export default {
  name: 'ActivitySecurity',
  mounted() {
    document.title = '特别福利';
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '28fdeefa-c820-4c8d-a7bd-6163eb0aab35';
    },
    activityId() {
      return 7;
    },
    businessType() { // 活动标识
      return 0;
    },
  },
  created() {
    this.cuserId && this.blindMonitor();
  },
  methods: {
    blindMonitor() {
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'cczqy_imp', {
        eventName: '长城证券活动曝光',
      });
    },
    jumpAction() {
      let { isPhone, isAndroid } = userAgentType();
      // isPhone && window?.ios?.jumpRoute('yyjz://app/discover?id=90');
      // isAndroid && window?.android?.jumpRoute('jz://com.caiyi.accounting/com.caiyi.accounting.jz.FinancialActivity');
      window.location.href = '//wap.xcsc.com/activity/OpenAccountJWKJ/home.html';
      window?._monitor('track', 'cczqy_msmflan_click', {
        eventName: '长城证券页-马上免费领',
      });
    },
    downloadAction() {
      let imgUrl = 'http://download-cos.yofish.com/yofish-gongjushiyebu/20221228135451918-qrocde.png';
      console.log(imgUrl);
      window?.ios?.saveImageToPhotosAlbum(imgUrl);
      window?.android?.saveWebImg(imgUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.security-page {
  width: 100%;
  max-width: 750px;
  margin: auto;
  font-family: OPPOSans-B, OPPOSans;
  background-color: rgb(215, 26, 46);
  .bg {
    width: 100%;
    background-image:
  url('//download-cos.yofish.com/yofish-gongjushiyebu/20221226151607219-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .welfare-card {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .rule-card {
    width: 715px;
    height: 971px;
    background-image:
  url('//download-cos.yofish.com/yofish-gongjushiyebu/20221226160105934-rule-card.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    overflow: hidden;
    .rule-content {
      margin: 72px 60px 82px 60px;
    }
    p {
      color: #401313;
      line-height: 45px;
      font-size: 22px;
    }
  }
  .qrcode-card {
    position: relative;
    width: 696px;
    height: 145px;
    background-image:
  url('//download-cos.yofish.com/yofish-gongjushiyebu/20221228135135917-frame-card.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 60px auto 0;
    .qrcode {
      position: absolute;
      width: 131px;
      height: 131px;
      background-image:
  url('//download-cos.yofish.com/yofish-gongjushiyebu/20221228135451918-qrocde.png');
    background-size: cover;
    background-repeat: no-repeat;
     right: 60px;
     top: -40px;
    }
  }
  .bottom-btn {
    width: 100%;
    height: 130px;
    background: linear-gradient(360deg, #FFC94A 0%, #FFEFC5 100%);
    text-align: center;
    line-height: 130px;
    font-size: 50px;
    color: #E92318;
  }
}
</style>
